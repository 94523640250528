import React, { useState, useEffect } from 'react';

const GeneratePopup = ({ onPageOpen }) => {
  const [instructions, setInstructions] = useState('');
  const [generating, setGenerating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [pageReady, setPageReady] = useState(false);
  const [uniqueId, setUniqueId] = useState(null);

  useEffect(() => {
    if (generating && !pageReady) {
      const interval = setInterval(() => {
        fetch(`https://sm8jcd6opc.execute-api.us-east-1.amazonaws.com/default/poll?unique_id=${uniqueId}`)
          .then(response => response.json())
          .then(isReady => {
            if (isReady) {
              setPageReady(true);
              setGenerating(false);
              setProgress(100);
              clearInterval(interval);
            }
          })
          .catch((error) => {
            console.error('Polling error:', error);
          });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [generating, pageReady, uniqueId]);

  const handleGenerateClick = () => {
    setGenerating(true);
    setProgress(0);
    setPageReady(false);

    const fakeProgressInterval = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress >= 95) {
          clearInterval(fakeProgressInterval);
          return 95;
        }
        return Math.min(oldProgress + 1, 95);
      });
    }, 1200);

    fetch('https://sm8jcd6opc.execute-api.us-east-1.amazonaws.com/default/generate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_prompt: instructions }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        setUniqueId(data.unique_id);
      })
      .catch((error) => {
        console.error('Error:', error);
        setGenerating(false);
        clearInterval(fakeProgressInterval);
      });
  };

  const handlePageClick = () => {
    onPageOpen(uniqueId);
    window.open(`/page/${uniqueId}`, '_blank');
  }

  return (
    <div className="popup">
      <h2>Welcome to the Wild Wild Web! 🤠</h2>
      {!generating && !pageReady &&
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', alignItems: 'center' }}>

          <h3>Enter your instructions below to generate a 90's style webpage.</h3>
          <textarea
            placeholder="Type your instructions here..."
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
            style={{ flex: 1, marginBottom: '10px', width: '90%' }} // Adjusted width of textarea
          />
          <button className="generateButton" onClick={handleGenerateClick}>
            <span style={{ position: 'relative', top: '-2px', marginRight: '7px' }}>🤖</span>
            Generate Webpage
          </button>
        </div>
      }
      {generating &&
        <>
          <p>Generating your webpage...</p>
          <div style={{ width: '100%', height: '20px', border: '1px solid black', marginTop: '10px' }}>
            <div style={{ height: '100%', width: `${progress}%`, backgroundColor: 'blue' }}></div>
          </div>
        </>
      }
      {pageReady && <p>Your page is done! <span style={{ color: '#f0f' }} onClick={() => handlePageClick()}>Click here to view it.</span></p>}
    </div>
  );
};

export default GeneratePopup;
