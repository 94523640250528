import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';

const ThumbnailGallery = ({ onRequestComplete }) => {
  const [thumbnails, setThumbnails] = useState([]);

  useEffect(() => {
    AWS.config.update({ region: 'us-east-1' });
    const s3 = new AWS.S3();

    s3.makeUnauthenticatedRequest(
      'listObjectsV2',
      { Bucket: 'geocities-space-html-thumbnails', Prefix: 'html_pages/' },
      (err, data) => {
        if (err) {
          console.log("Error", err);
          onRequestComplete && onRequestComplete(false);
        } else {
          const sortedData = data.Contents.sort((a, b) => b.LastModified - a.LastModified);
          const urls = sortedData.map(obj => ({
            url: `https://${data.Name}.s3.${AWS.config.region}.amazonaws.com/${obj.Key}`
          }));
          setThumbnails(urls);
          onRequestComplete && onRequestComplete(true);
        }
      }
    );
  }, [onRequestComplete]);

  useEffect(() => {
    if (thumbnails.length > 0) {
      const images = document.querySelectorAll('.thumbnail img');

      images.forEach(image => {
        const randomDuration = Math.random() * (1.5 - 0.5) + 0.5; // Duration between 0.5s and 1.5s
        const randomDelay = Math.random() * 0.5 + 0.2; // Delay up to 0.5s

        image.style.opacity = '0';
        image.style.animation = `scaleIn ${randomDuration}s ease ${randomDelay}s forwards`;
      });
    }
  }, [thumbnails]);

  const getHtmlPageUrl = (thumbnailUrl) => {
    // Take the filename part of the thumbnail URL
    const filename = thumbnailUrl.split('/').pop();

    // Remove the file extension
    const uniqueId = filename.split('.')[0];

    // Return the URL of the HTML page
    return `/page/${uniqueId}`;
  };

  return (
    <div className="thumbnail-gallery">
      {thumbnails.map((thumbnail, index) => (
        <a key={index} href={getHtmlPageUrl(thumbnail.url)} target="_blank" rel="noopener noreferrer" className="thumbnail">
          <img src={thumbnail.url} alt={`Thumbnail ${index}`} />
        </a>
      ))}
    </div>
  );
};

export default ThumbnailGallery;

