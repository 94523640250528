import React, { useState, useEffect } from 'react';
import discordLogo from './discordLogo.svg'; // adjust the path based on where you saved the SVG fileimport './App.css';

const DiscordButton = ({ }) => {
  const [events, setEvents] = useState([]);

  return (
    <div>
      <a href="https://discord.gg/AeuukD6ZmC" target="_blank" rel="noopener noreferrer">
          <img src={discordLogo} alt="Join Discord" className="discord-button" />     
      </a>
    </div>
  );
};

export default DiscordButton;