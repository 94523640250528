import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function Page() {
  let { id } = useParams();
  const [iframeSrc, setIframeSrc] = useState(null);

  useEffect(() => {
    const fetchAndSetIframeContent = async () => {
      try {
        const url = `https://geocities-space-html.s3.amazonaws.com/html_pages/${id}.html`;
        const response = await fetch(url);
        const data = await response.text();
        
        const blob = new Blob([data], { type: 'text/html' });
        setIframeSrc(URL.createObjectURL(blob));
      } catch (err) {
        console.error('Error fetching HTML content:', err);
      }
    };

    fetchAndSetIframeContent();

    return () => {
      if (iframeSrc) {
        URL.revokeObjectURL(iframeSrc);
        setIframeSrc(null);
      }
    };
  }, [id]);

  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      {iframeSrc && (
        <iframe 
          src={iframeSrc} 
          title="Page Content" 
          style={{ width: '100%', height: '100%', border: 'none' }}
          frameBorder="0"
        />
      )}
    </div>
  );
}

export default Page;

