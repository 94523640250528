import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import ThumbnailGallery from './ThumbnailGallery';
import GeneratePopup from './GeneratePopup';
import Page from './Page'; // Import the component for "/page/{id}"
import DiscordButton from './DiscordButton'; // import the component

function App() {
  const [loadingDone, setLoadingDone] = useState(false);
  const [showCreate, setShowCreate] = useState(false);

  const handleClick = () => {
    setShowCreate(true);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
        </header>
        <main>
          <Routes>
            <Route path="/" element={
              <>
                <ThumbnailGallery onRequestComplete={() => setLoadingDone(true)} />
                {!loadingDone && (
                  <div className="loadingLabel">
                    <span className="spinner"></span>Loading...
                  </div>
                )}
                {!showCreate && loadingDone && <>
                  <button className="create-button" onClick={handleClick}>Create</button>                  
                  <DiscordButton /></>
                }
                {showCreate && <GeneratePopup onPageOpen={() => setShowCreate(false)} />}
              </>
            }/>
            <Route path="/page/:id" element={<Page />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;